import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from "ngx-spinner";
import { TableModule } from 'primeng/table';
import { ModalModule } from './_modal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DatePipe, DecimalPipe } from '@angular/common';
import { DataService } from './services/data.service';
import { PublicquoteService } from './services/publicquote.service';
import { AuthGuard } from './services/auth-guard.service';

import { HeaderfrontComponent } from './headerfront/headerfront.component';
import { FooterfrontComponent } from './footerfront/footerfront.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BreadcrumComponent } from './breadcrum/breadcrum.component';

// User Idle state
import { UserIdleModule } from 'angular-user-idle';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ErrorIntercept } from './error.interceptor';
import { ImportPoliciesComponent } from './import-policies/import-policies.component';
import { JotFormComponent } from './jot-form/jot-form.component';

import { PaymentsTabComponent } from './payments-tab/payments-tab.component';


import { HeadersInterceptor } from './headers.interceptor';

// import { BuildersRiskComponent } from './builders-risk/builders-risk.component';
// import { WbrokerQuotesComponent } from './wbroker-quotes/wbroker-quotes.component';
// import { InsuredHomeComponent } from './insured-home/insured-home.component';
// import { InsuredHomeInfoComponent } from './insured-home-info/insured-home-info.component';


//Staging
  var firebaseConfig = {
    apiKey: "AIzaSyAEkKcH3rQssxYxVKBfRmq0p9jgSHneVXg",
    authDomain: "shieldstag2021.firebaseapp.com",
    databaseURL: "https://shieldstag2021-default-rtdb.firebaseio.com",
    projectId: "shieldstag2021",
    storageBucket: "shieldstag2021.appspot.com",
    messagingSenderId: "66021921330",
    appId: "1:66021921330:web:9abff25974181f81bb622b"
  };

@NgModule({
  declarations: [
    AppComponent,
    HeaderfrontComponent,
    FooterfrontComponent,
    LoginModalComponent,
    ForgotpasswordComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumComponent,
    ImportPoliciesComponent,
    JotFormComponent,
    PaymentsTabComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    NgxSpinnerModule,
    TableModule,
    ModalModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    UserIdleModule.forRoot({idle: 28800, timeout: 1, ping: 28800 })


  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' }, 
    DatePipe, 
    DecimalPipe, 
    DataService, 
    AuthGuard, 
    PublicquoteService, 
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HeadersInterceptor,
        multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
