import { Component, OnInit, Output, EventEmitter, OnDestroy, Renderer2, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../services/data.service';
import { ModalService } from '../_modal';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormGroup } from '@angular/forms';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationMessages } from '../validation-messages';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../assets/css/bootstrap.css', '../../assets/css/default.css', '../../assets/css/AdminLTE.css', '../../assets/css/_all-skins.css', '../../assets/css/fSelect.css', '../../assets/datatables.net/dataTables.bootstrap.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() loadNgOnInit = new EventEmitter();
  adminFormH: UntypedFormGroup;
  submittedh: any = false;
  serverEmailh = false;
  activeMenu: any;
  loggedStatus: any;
  loggedUserName: any;
  loggedUserDName: any;
  loggedPmaxUser: any;
  loggedUserRole: any;
  loggedUserId: any;
  hNotifications: any = [];
  hANotifications: any = [];
  unreadCount = '0';
  loginUserName: any;
  loginPassword: any;
  loginRemberMe: any;
  mySubscription: any;
  backgroundColor = '';
  logo_path: any;
  canhaveUserBroker:any = '0';
  isProduction:Boolean = false;
  domainname: any;
  buttoncolor: any;
  textColor:any;
  appversion: string;
  isLroAdmin: string;
  updateBrokerPassForm: FormGroup;
  confirmmailerror: boolean = false;
  submittedFA: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, public userService: UserService, private spinner: NgxSpinnerService, public router: Router, private modalService: ModalService, public dataService: DataService,private _renderer: Renderer2,@Inject(DOCUMENT) private _document,) {
    
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
       
        this.router.navigated = false;
      }
    });
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit() {  
    if (localStorage.getItem('wholesalerInfoObJ') !== null) {
      let wholesalerInfoArray = JSON.parse(localStorage.getItem('wholesalerInfoObJ'));
      if (wholesalerInfoArray.output == 'success')  {
        this.backgroundColor = wholesalerInfoArray.wholesalerInfo[0].wi_header_bg_color_code;
        this.logo_path = wholesalerInfoArray.wholesalerInfo[0].wi_logo_path;
        this.textColor = wholesalerInfoArray.wholesalerInfo[0].wi_text_bg_color_code;
        this.domainname = wholesalerInfoArray.wholesalerInfo[0].wi_domain;
        this.buttoncolor = wholesalerInfoArray.wholesalerInfo[0].wi_button_bg_color_code;
   
         $('.layout-top-nav').addClass('skin-blue');
         this.backgroundColor = this.backgroundColor + '! important';
        $('.navbar-static-top').attr('style', 'background-color:'+ this.backgroundColor);
        
      }
    }
    else {
      this.logo_path = 'assets/images/logo.png?v='+ValidationMessages.logoVersion;
      $('.layout-top-nav').addClass('skin-blue');
      $('.navbar-brand').removeClass('normandy');
    }

    var href = this.router.url;
    this.loggedUserDName = localStorage.getItem('u_name');
    this.loggedUserName = localStorage.getItem('u_email');
    this.loggedUserRole = localStorage.getItem('u_ur_id');
    this.loggedPmaxUser = localStorage.getItem('wholesalebroker');
    this.canhaveUserBroker = localStorage.getItem('canhaveUserBroker');
    this.loggedUserId = localStorage.getItem('u_id');
    this.loginUserName = localStorage.getItem('loginUserName');
    this.loginPassword = localStorage.getItem('loginPassword');
    this.loginRemberMe = localStorage.getItem('loginRememberme');
    if(localStorage.getItem('u_lro_admin') != undefined && localStorage.getItem('u_lro_admin') != null && localStorage.getItem('u_lro_admin') != ''){
        this.isLroAdmin = localStorage.getItem('u_lro_admin');
    }
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.dataService.currentHeaderDisplayName.subscribe(status => this.loggedUserName = status);


var environmentt = window.location.hostname;
if(environmentt == ValidationMessages.productionUrl || environmentt ==  ValidationMessages.productionUrlWithWww || environmentt == ValidationMessages.productionNormandyDomain || environmentt == ValidationMessages.productionCanopyDomain || environmentt == ValidationMessages.productionPbibinsDomain){
  localStorage.setItem('View_tab', '0');
  this.isProduction = true
}else{
  localStorage.setItem('View_tab', '1');
  this.isProduction = false
}
if(localStorage.getItem('u_ur_id') == '3'){
 
  var environmentt = window.location.hostname;

 
  if(environmentt == ValidationMessages.productionUrl || environmentt == ValidationMessages.productionUrlWithWww || environmentt == ValidationMessages.productionNormandyDomain || environmentt == ValidationMessages.productionCanopyDomain || environmentt == ValidationMessages.productionPbibinsDomain){
   
    
    const s = this._renderer.createElement('script');
    s.text = `var initESW = function(gslbBaseURL) {
        embedded_svc.settings.displayHelpButton = true; //Or false
        embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

        //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
        //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

        //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
        //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

        // Settings for Chat
        //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
            // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
            // Returns a valid button ID.
        //};
        embedded_svc.settings.prepopulatedPrechatFields = {
          FirstName: '`+localStorage.getItem('u_first_name')+`',
          LastName: '`+localStorage.getItem('u_last_name')+`',
          Email: '`+localStorage.getItem('u_email')+`'
        }; //Sets the auto-population of pre-chat form fields
        //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
        //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

        embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        embedded_svc.settings.entryFeature = 'LiveAgent';

        embedded_svc.init(
            'https://shieldcommercialinsuranceservices.my.salesforce.com',
            'https://shieldcommercialinsuranceservices.my.salesforce-sites.com/liveAgentSetupFlow',
            gslbBaseURL,
            '00D8c000007CdNO',
            'Rater_Queue',
            {
                baseLiveAgentContentURL: 'https://c.la5-c2-ia4.salesforceliveagent.com/content',
                deploymentId: '5728c000000YscJ',
                buttonId: '5738c0000001lIV',
                baseLiveAgentURL: 'https://d.la5-c2-ia4.salesforceliveagent.com/chat',
                eswLiveAgentDevName: 'Rater_Queue',
                isOfflineSupportEnabled: true
            }
        );
    };

    if (!window.embedded_svc) {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://shieldcommercialinsuranceservices.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        s.onload = function() {
            initESW(null);
        };
        document.body.appendChild(s);
    } else {
        initESW('https://service.force.com');
    }`;
    this._renderer.appendChild(this._document.body, s);
  }else{
    
    const s = this._renderer.createElement('script');
    s.text = `var initESW = function(gslbBaseURL) {
    embedded_svc.settings.displayHelpButton = true; //Or false
    embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

    //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
    //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    //};
    embedded_svc.settings.prepopulatedPrechatFields = {
      FirstName: '`+localStorage.getItem('u_first_name')+`',
      LastName: '`+localStorage.getItem('u_last_name')+`',
      Email: '`+localStorage.getItem('u_email')+`',
    }; //Sets the auto-population of pre-chat form fields
    //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    embedded_svc.init(
    'https://shieldcommercialinsuranceservices--qa.sandbox.my.salesforce.com',
    'https://shieldcommercialinsuranceservices--qa.sandbox.my.salesforce-sites.com/',
    gslbBaseURL,
    '00D8L0000008hU5',
    'Rater_Queue',
    {
    baseLiveAgentContentURL: 'https://c.la4-c1cs-ia5.salesforceliveagent.com/content',
    deploymentId: '5728L000000Gman',
    buttonId: '5738L000000GmcF',
    baseLiveAgentURL: 'https://d.la4-c1cs-ia5.salesforceliveagent.com/chat',
    eswLiveAgentDevName: 'Rater_Queue',
    isOfflineSupportEnabled: true
    }
    );
    };

    if (!window.embedded_svc) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://shieldcommercialinsuranceservices--qa.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js');
    s.onload = function() {
    initESW(null);
    };
    document.body.appendChild(s);
    } else {
    initESW('https://service.force.com');
    }`
    this._renderer.appendChild(this._document.body, s);
   
  }

 
}

    this.adminFormH = this.formBuilder.group({
      u_id: 0,
      u_name: ['', Validators.required],
      u_email: ['', [Validators.required, Validators.email]],
      u_password: ''
    });

    var postData = {};
    postData['u_id'] = localStorage.getItem('u_id');
    postData['type'] = 1;

    this.updateBrokerPassForm = this.formBuilder.group({
        up_pass: ['', Validators.required],
        up_conform_pass: ['', Validators.required]
    });


  }

  get fh() { return this.adminFormH.controls; }
  get ubp() { return this.updateBrokerPassForm.controls; }

  closeModalH(id: any) {
    this.modalService.close(id);
  }

  markAllAsRead() {
    this.spinner.show();
    this.userService.updateNotifications(this.loggedUserId, 0, 1).subscribe(() => {
      var postData = {};
      postData['u_id'] = localStorage.getItem('u_id');
      postData['type'] = 1;
      this.userService.getNotifications(postData).subscribe(data => {
        this.dataService.changeCurrentNotifications(data.notifications);
        this.dataService.currentNotifications.subscribe(status => this.hNotifications = status);
        this.dataService.changecurrentUnreadNCount(data.unreadCount);
        this.dataService.currentUnreadNCount.subscribe(status => this.unreadCount = status);
        this.spinner.hide();
      });
    });
  }

  markAllAsReadPop() {
    this.spinner.show();
    this.userService.updateNotifications(this.loggedUserId, 0, 1).subscribe(() => {
      var postData = {};
      postData['u_id'] = localStorage.getItem('u_id');
      postData['type'] = 1;
      this.userService.getNotifications(postData).subscribe(data => {
        this.dataService.changeCurrentNotifications(data.notifications);
        this.dataService.currentNotifications.subscribe(status => this.hNotifications = status);
        this.dataService.changecurrentUnreadNCount(data.unreadCount);
        this.dataService.currentUnreadNCount.subscribe(status => this.unreadCount = status);

        var postDataa = {};
        postDataa['u_id'] = localStorage.getItem('u_id');
        postDataa['type'] = 2;
        this.userService.getNotifications(postDataa).subscribe(data => {
          this.hANotifications = data.notifications;
          this.spinner.hide();
        });

      });
    });
  }


  closeNotificationPop() {
    this.modalService.close('view-all-notifications');
  }

  openNotificationPop() {
    this.spinner.show();
    var postData = {};
    postData['u_id'] = localStorage.getItem('u_id');
    postData['type'] = 2;
    this.userService.getNotifications(postData).subscribe(data => {
      this.hANotifications = data.notifications;
      this.modalService.open('view-all-notifications');
      this.spinner.hide();
    });

  }

  saveAdminH() {
    this.serverEmailh = false;
    this.submittedh = true;
    if (this.adminFormH.invalid) {
      return;
    }
    var postData = {};
    postData = this.adminFormH.value;
    postData['type'] = 1;
    this.spinner.show();
    this.userService.saveAdmin(postData).subscribe(data => {
      this.spinner.hide();
      if (data.output == 0) {
        this.serverEmailh = true;
      } else {
        this.modalService.close('add-edit-modal-header');
        localStorage.setItem('u_email', this.adminFormH.value.u_email);
        localStorage.setItem('u_name', this.adminFormH.value.u_name);
        this.dataService.changeCurrentHeaderDisplayName(this.adminFormH.value.u_email);
        this.dataService.currentHeaderDisplayName.subscribe(status => this.loggedUserName = status);
      }
    });
  }

  goToHome() {
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      if (this.loggedUserRole == 1 || this.loggedUserRole == 2) {
        this.goToQuotes();
      } else {
        this.goToQuotes();
      }
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        if (this.loggedUserRole == 1 || this.loggedUserRole == 2) {
          this.router.navigate(['/quotes']);
        } else {
          this.goToQuotes();
        }
      } else {
        this.logoutConfirm();
      }
    }
  }

  openQuoteNotPopup(quoteId: any, notifiId: any) {
    this.modalService.close('view-all-notifications');
    this.openQuote(quoteId, notifiId);
  }

  openQuoteHistoryNotpopup(quoteId: any, notifiId: any) {
    this.modalService.close('view-all-notifications');
    this.openQuoteHistory(quoteId, notifiId);
  }

  openQuoteHistory(quoteId: any, notifiId: any) {
    this.spinner.show();
    this.userService.updateNotifications(this.loggedUserId, quoteId, 4).subscribe(() => {
      localStorage.setItem('active_tab', '2');
      this.dataService.changeCurrentHeaderActive('2');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      localStorage.setItem('new_quote_id', '2');
      localStorage.setItem('qu_id', quoteId);
      this.dataService.changecurrentBreadcrumQuote(quoteId);
      var postData = {};
      postData['u_id'] = localStorage.getItem('u_id');
      postData['type'] = 1;
      this.userService.getNotifications(postData).subscribe(data => {
        this.dataService.changeCurrentNotifications(data.notifications);
        this.dataService.currentNotifications.subscribe(status => this.hNotifications = status);
        this.dataService.changecurrentUnreadNCount(data.unreadCount);
        this.dataService.currentUnreadNCount.subscribe(status => this.unreadCount = status);
        if (this.router.url.includes('/quote-history')) {
          
          localStorage.setItem('previousQuoteId', localStorage.getItem('currentQuoteId'));
          localStorage.setItem('currentQuoteId', quoteId);
        
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/quote-history', quoteId])
          );
        }
        else {
          this.router.navigate(['/quote-history', quoteId]);
        }
        this.spinner.hide();
      });
    });
  }

  openQuote(quoteId: any, notifiId: any) {
    this.spinner.show();
    this.userService.updateNotifications(this.loggedUserId, notifiId, 2).subscribe(() => {
      localStorage.setItem('active_tab', '2');
      this.dataService.changeCurrentHeaderActive('2');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      localStorage.setItem('new_quote_id', '2');
      localStorage.setItem('qu_id', quoteId);
      this.dataService.changecurrentBreadcrumQuote(quoteId);
      var postData = {};
      postData['u_id'] = localStorage.getItem('u_id');
      postData['type'] = 1;
      this.userService.getNotifications(postData).subscribe(data => {
        this.dataService.changeCurrentNotifications(data.notifications);
        this.dataService.currentNotifications.subscribe(status => this.hNotifications = status);
        this.dataService.changecurrentUnreadNCount(data.unreadCount);
        this.dataService.currentUnreadNCount.subscribe(status => this.unreadCount = status);
        if (this.router.url.includes('/quote-summary')) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/quote-summary', quoteId])
          );
        }
        else {
          this.router.navigate(['/quote-summary', quoteId]);
        }
        this.spinner.hide();
      });
    });
  }

  goToQuotes() {
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '2');
      this.dataService.changeCurrentHeaderActive('2');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      if (this.loggedUserRole == 3) {
        localStorage.setItem('brokerViewId', this.loggedUserId);
        if(localStorage.getItem('wholesalebroker') == '1' || localStorage.getItem('canhaveUserBroker') == '1' || localStorage.getItem('checkDisplayQuotes') == '1'){
          this.router.navigate(['/wbroker-quotes']);
        }else{
          this.router.navigate(['/broker-quotes']);
        }
       
      } else {
        this.router.navigate(['/quotes']);
      }
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        this.router.navigate(['/home']);
      } else {
        this.logoutConfirm();
      }
    }
  }

  goToBrokers() {
    this.router.navigate(['/brokers']);
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '3');
      this.dataService.changeCurrentHeaderActive('3');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.router.navigate(['/brokers']);
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        this.router.navigate(['/home']);
      } else {
        this.logoutConfirm();
      }
    }
  }

  goToClients() {
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '4');
      this.dataService.changeCurrentHeaderActive('4');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.router.navigate(['/clients']);
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        this.router.navigate(['/home']);
      } else {
        this.logoutConfirm();
      }
    }

  }

  goToAdmins() {
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '5');
      this.dataService.changeCurrentHeaderActive('5');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.router.navigate(['/admins']);
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        this.router.navigate(['/home']);
      } else {
        this.logoutConfirm();
      }
    }
  }

  goToClaims() {
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '9');
      this.dataService.changeCurrentHeaderActive('9');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.router.navigate(['/old-list/1']);
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        this.router.navigate(['/home']);
      } else {
        this.logoutConfirm();
      }
    }
  }

  goToTutorials() {
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '10');
      this.dataService.changeCurrentHeaderActive('10');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.router.navigate(['/tutorials']);
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        this.router.navigate(['/home']);
      } else {
        this.logoutConfirm();
      }
    }
  }
  goToPayments() {
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '11');
      this.dataService.changeCurrentHeaderActive('11');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.router.navigate(['/payments-tab']);
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        this.router.navigate(['/home']);
      } else {
        this.logoutConfirm();
      }
    }
  }

  logoutPortal() {
    this.modalService.open('logout-modal');
  }

  logoutConfirmCancel() {
    this.modalService.close('logout-modal');
  }

  logoutConfirm() {
    this.spinner.show();
    this.modalService.close('logout-modal');
    this.loginUserName = localStorage.getItem('loginUserName');
    this.loginPassword = localStorage.getItem('loginPassword');
    this.loginRemberMe = localStorage.getItem('loginRememberme');
    this.appversion = localStorage.getItem("appversion");  
    localStorage.clear();

    localStorage.setItem('active_tab', '1');


    
    localStorage.setItem('loginUserName', this.loginUserName);
    localStorage.setItem('loginPassword', this.loginPassword);
    localStorage.setItem('loginRememberme', this.loginRemberMe);
    localStorage.setItem("appversion", this.appversion);
   
    this.spinner.hide();

 
    window.location.href = "/home";
  }

  goToMailTemp() {
    localStorage.setItem('active_tab', '0');
    this.dataService.changeCurrentHeaderActive('0');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/mail-templates']);
  }

  goToMailSign() {
    localStorage.setItem('active_tab', '0');
    this.dataService.changeCurrentHeaderActive('0');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/mail-signatures']);
  }

  goToReports() {
    localStorage.setItem('active_tab', '8');
    this.dataService.changeCurrentHeaderActive('8');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/reports']);
  }

  goToProfile() {
    if (this.loggedUserRole == 3) {
      localStorage.setItem('active_tab', '0');
      this.dataService.changeCurrentHeaderActive('0');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      localStorage.setItem('brokerViewId', this.loggedUserId);
      this.router.navigate(['/broker']);
    } else if (this.loggedUserRole == 2) {
      this.modalService.open('add-edit-modal-header');
      this.adminFormH.patchValue({
        u_id: this.loggedUserId,
        u_name: localStorage.getItem('u_name'),
        u_email: localStorage.getItem('u_email'),
        u_password: '',
      });
    }
  }

  goToDQuotes() {
    localStorage.setItem('active_tab', '2');
    this.dataService.changeCurrentHeaderActive('2');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/quote-tab']);
  }

  goToDSQuotes() {
    localStorage.setItem('active_tab', '2');
    this.dataService.changeCurrentHeaderActive('2');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/quote-tab-search']);
  }

    goToJotForm(){
        this.router.navigate(['/jot-form']);
    }
    navbarcollapse(){
  
    $('.navbar-collapse').removeClass('in');
    $('.navbar-toggle').addClass('collapsed');
    
    }

    openChangePasswordModel() {
        this.modalService.open('change-pass-modal');
    }

    closeChangePasswordModel() {
        this.confirmmailerror = false;
        this.submittedFA = false;
        this.modalService.close('change-pass-modal');
    }

    changePassword() {
        this.submittedFA = true;
        if (this.updateBrokerPassForm.invalid) {
            return;
        }

        if (this.updateBrokerPassForm.value.up_pass != this.updateBrokerPassForm.value.up_conform_pass) {
            this.confirmmailerror = true;
            return;
        }
        this.submittedFA = false;
        this.confirmmailerror = false;
        var postdata = {};
        postdata['updateBrokerPassForm'] = this.updateBrokerPassForm.value;
        postdata['typeOfUpdate'] = 0;
        postdata['brokerId'] = this.loggedUserId;
        postdata['u_email'] = this.loggedUserName;
        this.spinner.show();
        this.userService.brokerUpdatePassword(postdata).subscribe(data => {
            this.spinner.hide();
            this.modalService.close('change-pass-modal');
            this.modalService.open('warning-modal5');
            this.updateBrokerPassForm.reset();
        });
    }

    onChangePassword() {
        this.confirmmailerror = false;
        this.submittedFA = false;
    }

    closeWarnningModal() {
        this.modalService.close('warning-modal5');
    }

}
