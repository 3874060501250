import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ValidationMessages } from '../validation-messages';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css', '../../assets/css/bootstrap.css', '../../assets/css/default.css', '../../assets/css/AdminLTE.css', '../../assets/css/_all-skins.css', '../../assets/css/fSelect.css', '../../assets/datatables.net/dataTables.bootstrap.css']
})
export class FooterComponent implements OnInit {

  domainname = "";
  currentTime:any;
  year:any;
  requestURL: string;
  constructor(public router: Router) {
    this.currentTime = new Date();
    this.year = this.currentTime.getFullYear();
    var appUrl: string;
    var environmentt: string;
    environmentt = window.location.hostname;

    switch (environmentt) {
      case 'localhost':
        appUrl = 'https://shield-uploads-bucket.s3.us-east-2.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionUrl):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionUrlWithWww):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionNormandyDomain):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionCanopyDomain):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionPbibinsDomain):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      default:
        appUrl = 'https://shield-uploads-bucket.s3.us-east-2.amazonaws.com/shield-view-form-pdfs/';
    }
    this.requestURL = appUrl;
   }
  

  ngOnInit() {

    if (localStorage.getItem('wholesalerInfoObJ') !== null) {
      let wholesalerInfoArray = JSON.parse(localStorage.getItem('wholesalerInfoObJ'));
      if (wholesalerInfoArray.output == 'success') {
        this.domainname = wholesalerInfoArray.wholesalerInfo[0].wi_domain;
        if (this.domainname == ValidationMessages.stagingNormandyDomain || this.domainname == ValidationMessages.productionNormandyDomain || this.domainname == ValidationMessages.localNormandyDomain ) {
            this.domainname = 'normandy';
        }
        if (this.domainname == ValidationMessages.stagingCanopyDomain || this.domainname == ValidationMessages.productionCanopyDomain) {
          this.domainname = 'canopy';
      }
      if (this.domainname == ValidationMessages.stagingPbibinsDomain || this.domainname == ValidationMessages.productionPbibinsDomain ) {
        this.domainname = 'pbibins';
    }

      }
   }
  }
  
    openDoc(type: number){
        let docUrl = '';
        if(type == 1){
            docUrl = this.requestURL + 'sPrivacy.pdf';
            window.open(docUrl, "_blank");
        } else {
            docUrl = this.requestURL + 'sTerms.pdf';
            window.open(docUrl, "_blank");
        }
    }
}
