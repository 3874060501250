import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ValidationMessages } from '../validation-messages';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiURL = "";
  loginUserName = "";
  loginPassword = "";
  loginRemberMe = "";
  appversion = "";
  constructor(private httpClient: HttpClient) {
    var environmentt: string;
    var appUrl: string;
    environmentt = window.location.hostname;
    switch (environmentt) {
      case 'localhost':
        appUrl = 'http://localhost:8000/api';
        break;
      case (ValidationMessages.productionUrl):
        appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionUrlWithWww):
        appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionNormandyDomain):
        appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionCanopyDomain):
        appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionPbibinsDomain):
        appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      default:
        appUrl = ValidationMessages.stagingUrl + '/api';
    }
    this.apiURL = appUrl;
  }

  randomString(length) {
    var text = "";
    var possible = 'abcdefghijklmnopqrstuvwxyz'
      + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      + '0123456789!@#$%^&*()';
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  getNewToken() {
    if (typeof localStorage.getItem('loggedUserToken') != undefined && localStorage.getItem('loggedUserToken') != "" && localStorage.getItem('loggedUserToken') != null && localStorage.getItem('loggedUserToken') != "0") 
    {
      let localStToken = localStorage.getItem('loggedUserToken');
      let decryptToken = atob(atob(btoa(atob(localStToken))));
      let currentIntValue = parseInt(decryptToken.charAt(7) + '' + decryptToken.charAt(25) + '' + decryptToken.charAt(37));
      let nextIntValue = 100;
      if (currentIntValue < 999) {
        nextIntValue = currentIntValue + 1;
      }
      let token = this.randomString(7) + '' + nextIntValue.toString().charAt(0) + '' + this.randomString(17) + '' + nextIntValue.toString().charAt(1) + '' + this.randomString(11) + '' + nextIntValue.toString().charAt(2) + '' + this.randomString(6);
      let encryptToken = btoa(btoa(atob(btoa(token))));
      localStorage.setItem('loggedUserToken', encryptToken);
      return encryptToken;
    }
    else
    {
      return 0;
    }
  }

  public openReports(postData: any, reportType: any): Observable<any> {
    let url = "";
    if (reportType == 1 || reportType == 2 || reportType == 3) {
      url = `${this.apiURL}/policies-report-pdf`;
    } else if (reportType == 4) {
      url = `${this.apiURL}/policies-ar-report-excel`;
    } else if (reportType == 5 || reportType == 6 || reportType == 7) {
      url = `${this.apiURL}/activity-report-pdf`;
    } else if (reportType == 8) {
      url = `${this.apiURL}/classes-report-pdf`;
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public saveFiles(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-files`;
    return this.httpClient.post(url, postData);
  }
  public saveCompletedOfflineFiles(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-inspection-offline-files`;
    return this.httpClient.post(url, postData);
  }
  public saveMailUploadFiles(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-mail-upload-files`;
    return this.httpClient.post(url, postData);
  }
  public saveMultipleFilesInspection(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-multiple-files-ins`;
    return this.httpClient.post(url, postData);
  }
  // Save Multiple file uploads
  public saveMultipleFiles(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-multiple-files`;
    return this.httpClient.post(url, postData);
  }
  public shWCMultiUpload(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-loss-wc-multiple`;
    return this.httpClient.post(url, postData);
  }
  public delauditUploads(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/get-audit-admin-upload-del`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public saveMultipleFilesClaims(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-multiple-files-claims`;
    return this.httpClient.post(url, postData);
  }
  public deleteClaimFile(postData: any): Observable<any> {
    const url = `${this.apiURL}/delete-claim-file`;
    return this.httpClient.post(url, JSON.stringify(postData));
  }
  //save excess multiple files
  public saveMultipleFilesExcess(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-multiple-files-excess`;
    return this.httpClient.post(url, postData);
  }
  public delExMultiFileOrg(postData: any): Observable<any> {
    const url = `${this.apiURL}/delete-multi-file`;
    return this.httpClient.post(url, postData);
  }
  // Save notes
  public saveNote(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-note`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public getTimestams(): Observable<any> {
    const url = `${this.apiURL}/get-timeStamp`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  // Save Broker notes
  public saveBrokerNote(postData: any): Observable<any> {
    const url = `${this.apiURL}/request-add-broker-notes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // add new broker child users
  public addBrokerNewUser(postData: any): Observable<any> {
    const url = `${this.apiURL}/request-broker-new-user`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public deleteBrokerNewUser(postData: any): Observable<any> {
    const url = `${this.apiURL}/request-broker-delete-user`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public saveMultiFiles(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-history-multiple`;
    return this.httpClient.post(url, postData);
  }
  public saveMailMultiAtt(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-mail-attch-multiple`;
    return this.httpClient.post(url, postData);
  }
  // 
  public saveuploadMultiFiles(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-upload-history-multiple`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }


  public getNotifications(postData: any): Observable<any> {
    postData['accessToken'] = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');
    if(token_unique_id == "" || token_unique_id == null || token_unique_id == "null" || typeof token_unique_id == undefined ){
      localStorage.setItem('loggedInMbId','ff682d71fbf6b8581a0fc91f282bae37');
    }
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');
    const url = `${this.apiURL}/get-notifications`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public updateNotifications(userId: any, notifiId: any, type: any) {
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');
    if(token_unique_id == "" || token_unique_id == null || token_unique_id == "null" || typeof token_unique_id == undefined ){
      localStorage.setItem('loggedInMbId','ff682d71fbf6b8581a0fc91f282bae37');
    }
    token_unique_id = localStorage.getItem('loggedInMbId');
    const url = `${this.apiURL}/update-notifications/${userId}-${notifiId}-${type}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public createNewQuote(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-new-quote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public saveAdmin(postData: any): Observable<any> {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/save-admin`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public userOperations(postData: any) {
    const url = `${this.apiURL}/user-operations`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getDList(type: any, userId: any) {
    var loggedUserId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/user-operations-quote/show/${type}-${userId}-${loggedUserId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getList(type: any, userId: any) {
    var loggedUserId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/user-operations/show/${type}-${userId}-${loggedUserId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public brokerOperations(postData: any) {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/broker-operations`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public brokerUpdatePassword(postData: any) {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/request-broker-update-pass`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public brokerAuotUpdateAssist(userId: any, type:any) {
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/broker-auto-update-assist/${userId}-${type}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getBrokerInfo(userId: any) {
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/broker-operations/show/${userId}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public saveSetting(postData: any): Observable<any> {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/settings`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public sendEndApproveMail(postData: any): Observable<any> {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/send-end-appr-mail`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public saveEndMail(postData: any): Observable<any> {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/save-end-cron-mail`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getSettings(Id: any, userID: any) {
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/settings/show/${Id}-${userID}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getRowList(quoteId: any): Observable<any> {
    var loggedUserId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/user-operations/show/11-${quoteId}-${loggedUserId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  // Move Quote Broker
  public moveQuoteToBroker(postData: any): Observable<any> {
    const url = `${this.apiURL}/move-quote-to-broker`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  //  Quote Delete
  public quoteDelete(postData: any): Observable<any> {
    const url = `${this.apiURL}/quote-delete`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  //  Quote Delete
  public cpplquoteDelete(postData: any): Observable<any> {
    const url = `${this.apiURL}/cpplquotedelete`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  // Notification send to All renewal Admins
  public sendNotToRenewalAdmins(postData: any): Observable<any> {
    const url = `${this.apiURL}/send-not-to-renewal-admins`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // End 
  //  save Non Renewal Info
  public saveNonRenewalInfo(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-non-renewal-info`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // End
  // Send Document To Broker
  public sendNonRenewalDocumentToBroker(postData: any): Observable<any> {
    const url = `${this.apiURL}/send-non-renewal-document-to-broker`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // End 
  public getBokersList(postData: any): Observable<any> {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/get-broker-list`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public getInspectionNocPdf(postData: any): Observable<any> {
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/get-inspection-noc-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public viewChanges(postData: any): Observable<any> {
    const url = `${this.apiURL}/view-changes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public backtonewstatusviewChanges(postData: any): Observable<any> {
    const url = `${this.apiURL}/newstatus-view-changes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public viewEndorsementChanges(postData: any): Observable<any> {
    const url = `${this.apiURL}/view-endorsement-changes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public viewEndorsementadditionalChanges(postData: any): Observable<any> {
    const url = `${this.apiURL}/view-endorsement-additional-changes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public saveEndExc(postData: any): Observable<any> {
    const url = `${this.apiURL}/save-endorsement-exceptions`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getEndExc(quoteId: any,mode:number = 1): Observable<any> {
    const url = `${this.apiURL}/get-endorsement-exceptions/show/${quoteId}-${mode}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public assignRenewalQuotesToAdmin(postData: any): Observable<any> {
    const url = `${this.apiURL}/assign-renewal-quotes-to-admin`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // copy quote 
  public issuedCopyQuotesToAdmin(postData: any): Observable<any> {
    const url = `${this.apiURL}/issued-copy-quote-admin`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // Get wholesaler domain info get service

  public getWholesalerInfo(domain: any): Observable<any> {
    const url = `${this.apiURL}/get-wholesaler-info/${domain}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public imuFormSubmit(postData: any, postUrl: any): Observable<any> {
    const url = `${this.apiURL}/${postUrl}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public equipInfo(postData: any): Observable<any> {
    const url = `${this.apiURL}/imu-form-calculation`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  
  public getWholesalerCommissionApplyByDate(wholesalerId:any,packageType:any,effectiveDate:any): Observable<any> {
    const url = `${this.apiURL}/get-wholesaler-commission-apply-by-date/${wholesalerId}/${packageType}/${effectiveDate}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getAgencyInfo(userId: any, userInfoType: any): Observable<any> {
    const url = `${this.apiURL}/get-agency-info/${userId}-${userInfoType}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getAllBrokers(typeInfo: any): Observable<any> {
    const url = `${this.apiURL}/get-all-brokers/${typeInfo}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getQuoteAllDocuments(typeInfo: any): Observable<any> {
    const url = `${this.apiURL}/show-quote-all-documents/${typeInfo}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public saveImuInfo( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/save-imu-info`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public getImuInfo(Id: any): Observable<any> {
    const url = `${this.apiURL}/get-imu-quote-info/${Id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public deleteClaim(postData: any): Observable<any> {
    const url = `${this.apiURL}/delete-quote-claims`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public deleteAudit(postData: any): Observable<any> {
    const url = `${this.apiURL}/delete-quote-audit`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getBrokerEmailsAndTemplate(postDataa: any,type = 1): Observable<any> {
    var postData = {};
    postData['allUserIds'] = postDataa;
    postData['type'] = type;
    const url = `${this.apiURL}/get-broker-emails-and-template`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public sendWelcomeEmailsToBrokers(postData: any): Observable<any> {
    const url = `${this.apiURL}/send-welcome-emails-to-brokers`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public sendPayNotificationMailToBrokers(postData: any): Observable<any> {
    const url = `${this.apiURL}/send-paymentnotification-emails-to-brokers`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  // Move endorsement to underwriter
  public moveEndorsementToUnderwriter(postData: any): Observable<any> {
    const url = `${this.apiURL}/move-endorsement-to-underwriter`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  
  public cpplnewcopyquote(postData: any): Observable<any> {
    const url = `${this.apiURL}/co-issued-copy-quote-admin`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public nonglnewcopyquote(postData: any): Observable<any> {
    const url = `${this.apiURL}/nongl-issued-copy-quote-admin`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public versionmatch(currentversion:any){
    
    const url = `${this.apiURL}/checkveriosnupdate`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(currentversion), options);

  }

  public endorsementholding(postData: any): Observable<any> {
    
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/endorsement-holding`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public updateendorsementstatus(postData: any): Observable<any> {  
    const url = `${this.apiURL}/endorsement-statusupdate`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public viewLroEndorsementChanges(postData: any): Observable<any> {
    const url = `${this.apiURL}/view-lro-endorsement-changes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  

    public getOauthTokenDetails(postData: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        let options = { headers: headers };
        let email = postData.u_email;
        let password = postData.u_password;
        postData = {
            username: email,
            password: password,
            grant_type: 'password',
            client_id: 4,
            client_secret: 'sSjlbkSSYOGbKt35r8Jz9WCpAfTBjUXfdKY4Ibrl',
            scope: '*'
        };
        return this.httpClient.post(`${this.apiURL}/oauth/token`, postData);
    }

    getNewOauthTokenDetails(){
        const refresh_token = localStorage.getItem('refresh_token');
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        let options = { headers: headers };
        const postData = {
            grant_type: 'refresh_token',
            client_id: 4,
            client_secret: 'sSjlbkSSYOGbKt35r8Jz9WCpAfTBjUXfdKY4Ibrl',
            refresh_token: refresh_token
        }
        return this.httpClient.post<any>(`${this.apiURL}/oauth/token`, postData);
    }

    logout() {
        this.loginUserName = localStorage.getItem('loginUserName');
        this.loginPassword = localStorage.getItem('loginPassword');
        this.loginRemberMe = localStorage.getItem('loginRememberme');
        this.appversion = localStorage.getItem("appversion");  
        localStorage.clear();

        localStorage.setItem('active_tab', '1');
        
        localStorage.setItem('loginUserName', this.loginUserName);
        localStorage.setItem('loginPassword', this.loginPassword);
        localStorage.setItem('loginRememberme', this.loginRemberMe);
        localStorage.setItem("appversion", this.appversion);
    }

    checkExpireTime(){

      if(!localStorage.getItem('access_token') || !localStorage.getItem('refresh_token')){
        this.logout();
        window.location.href = "/home";
        return;
      }
       
        const current_time = new Date().getTime() / 1000;
        const token_expire_time = +localStorage.getItem('actual_token_expiration_time');
        if (current_time > token_expire_time) {
            return true;
        } 
        return false;
    }

    setTokenDetails(pData) {
        const current_time = new Date().getTime() / 1000;
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expire_time');
        localStorage.setItem('access_token', pData.access_token);
        localStorage.setItem('refresh_token', pData.refresh_token);
        localStorage.setItem('token_expire_time', pData.expires_in)
        const actual_token_expiration_time = current_time + (+localStorage.getItem('token_expire_time') - 30);
        localStorage.setItem('actual_token_expiration_time', actual_token_expiration_time.toString())
    }

    async checkTimeAndSetToken(){
        if(this.checkExpireTime()){
            await this.getNewOauthTokenDetails().toPromise().then(
                pData => {
                    this.setTokenDetails(pData);
                }
            ).catch((error: any) => {
                 
                this.logout();
                window.location.href = "/home";
                throw error;
            });
        }
    }

}