import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { ValidationMessages } from '../validation-messages';

@Component({
  selector: 'app-footerfront',
  templateUrl: './footerfront.component.html',
  styleUrls: ['./footerfront.component.css', '../../assets/fcss/bootstrap.css', '../../assets/fcss/default.css', '../../assets/fcss/AdminLTE.css', '../../assets/fcss/_all-skins.css']
})
export class FooterfrontComponent implements OnInit {

  domainname = '';
  logopath = "";
  logo_path:any;
  currentTime:any;
  year:any;
  requestURL: string;

  @Input() domainData: any;
  constructor(public router: Router,public userService: UserService) {
    this.currentTime = new Date();
    this.year = this.currentTime.getFullYear();
    var appUrl: string;
    var environmentt: string;
    environmentt = window.location.hostname;

    switch (environmentt) {
      case 'localhost':
        appUrl = 'https://shield-uploads-bucket.s3.us-east-2.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionUrl):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionUrlWithWww):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionNormandyDomain):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionCanopyDomain):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
      case (ValidationMessages.productionPbibinsDomain):
        appUrl = 'https://prod-shield-ins.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
        break;
 
      default:
        appUrl = 'https://shield-uploads-bucket.s3.us-east-2.amazonaws.com/shield-view-form-pdfs/';
    }
    this.requestURL = appUrl;
   }

  ngOnInit() {
    var domain = location.hostname + (location.port ? ':' + location.port : '');

      let wholesalerInfoArray = this.domainData;
      if (wholesalerInfoArray.output == 'success') { 
        this.domainname = wholesalerInfoArray.wholesalerInfo[0].wi_domain;
        this.logo_path = wholesalerInfoArray.wholesalerInfo[0].wi_logo_path;
        if (this.domainname == ValidationMessages.stagingNormandyDomain || this.domainname == ValidationMessages.productionNormandyDomain || this.domainname == ValidationMessages.localNormandyDomain ) {
          this.domainname = 'normandy';
          this.logopath = 'assets/images/normandy-logo_white.png';
        }
        if (this.domainname == ValidationMessages.stagingCanopyDomain || this.domainname == ValidationMessages.productionCanopyDomain ) {
          this.domainname = 'canopy';
          this.logopath = 'assets/images/Canopy-white-bg.png';
        }
        if (this.domainname == ValidationMessages.stagingPbibinsDomain || this.domainname == ValidationMessages.productionPbibinsDomain ) {
          this.domainname = 'pbibins';
          this.logopath = 'assets/images/Canopy-white-bg.png';
      }
  
      }
      else{
        this.logopath = 'assets/images/logo.png?v='+ValidationMessages.logoVersion;
      }

  }

  openDoc(type: number){
        let docUrl = '';
        if(type == 1){
            docUrl = this.requestURL + 'sPrivacy.pdf';
            window.open(docUrl, "_blank");
        } else {
            docUrl = this.requestURL + 'sTerms.pdf';
            window.open(docUrl, "_blank");
        }
    }
}
