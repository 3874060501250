import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { throwError } from 'rxjs';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable } from "rxjs";
import { catchError, switchMap, tap, filter, take } from "rxjs/operators";
import { UserService } from "./services/user.service";
@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private userService: UserService){}

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     // console.log(request);
    //     if (request.url.includes('/get-all-admins') || request.url.includes('/check-quote-access')) {
    //         const current_time = new Date().getTime() / 1000;
    //         const token_expire_time = +localStorage.getItem('actual_token_expiration_time');
    //         if (current_time > token_expire_time) {
    //             this.userService.getNewOauthTokenDetails().pipe(
    //                 tap(
    //                     response => {
    //                         localStorage.setItem('access_token', response.access_token);
    //                         localStorage.setItem('refresh_token', response.refresh_token);
    //                         localStorage.setItem('token_expire_time', response.expires_in)
    //                         const actual_token_expiration_time = current_time + (+localStorage.getItem('token_expire_time') - 2);
    //                         localStorage.setItem('actual_token_expiration_time', actual_token_expiration_time.toString());
    //                     }
    //                 ), 
    //                 switchMap(() => {
    //                     let token = localStorage.getItem('access_token');
    //                     const req = request.clone({
    //                         setHeaders: {
    //                             Authorization: `Bearer ${token}`
    //                         }
    //                     });
    //                     return next.handle(req);
    //                 })
    //             ).subscribe();
    //         } else {
    //             let token = localStorage.getItem('access_token');
    //             const req = request.clone({
    //                 setHeaders: {
    //                     Authorization: `Bearer ${token}`
    //                 }
    //             });
    //             return next.handle(req); 
    //         }// Pass the request along without interception
    //     } else {
    //         return next.handle(request);
    //     }
    // }

    // working correct with time errror
    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     if (request.url.includes('/get-all-admins') || request.url.includes('/check-quote-access') ||
    //     request.url.includes('/get-quote-summary-main') || request.url.includes('/get-quote-summary-insured-info') ||
    //     request.url.includes('/get-quote-summary-claims-audits-inspections') || request.url.includes('/get-quote-summary-pdf-documents') ||
    //     request.url.includes('/get-quote-summary-file-notes') || request.url.includes('/check-quote-access')
    //     ){
    //         let token = localStorage.getItem('access_token');
    //         const current_time = new Date().getTime() / 1000;
    //         const token_expire_time = +localStorage.getItem('actual_token_expiration_time');
    //         if (current_time > token_expire_time) {
    //             return this.userService.getNewOauthTokenDetails().pipe(
    //                 switchMap((newToken) => {
    //                     localStorage.removeItem('access_token');
    //                     localStorage.removeItem('refresh_token');
    //                     localStorage.removeItem('token_expire_time');
    //                     localStorage.setItem('access_token', newToken.access_token);
    //                     localStorage.setItem('refresh_token', newToken.refresh_token);
    //                     localStorage.setItem('token_expire_time', newToken.expires_in)
    //                     const actual_token_expiration_time = current_time + (+localStorage.getItem('token_expire_time') - 2);
    //                     localStorage.setItem('actual_token_expiration_time', actual_token_expiration_time.toString())
    //                     token = localStorage.getItem('access_token');
    //                     const modifiedRequest = this.addTokenToRequest(request, token);
    //                     return next.handle(modifiedRequest);
    //                 }),
    //                 catchError((refreshError) => {
    //                     this.logout();
    //                     return throwError(refreshError);
    //                 })
    //             );
    //         } else {
    //             const modifiedReques = this.addTokenToRequest(request, token);
    //             return next.handle(modifiedReques);
    //         }
    //     } else {
    //         return next.handle(request);
    //     }
    // }

    // working when got error fine
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.checkUrl(request)){
            let token = localStorage.getItem('access_token');
            if(token != undefined && token != null) {
                request = this.addTokenToRequest(request, token);
            }
            return next.handle(request).pipe(
                catchError((error) => {
                    if (typeof error === 'string') {
                        // Extract the status code and message from the string
                        const matches = error.match(/(\d{3})\s(.+)/);
                        if (matches && matches.length >= 3) {
                            const statusCode = parseInt(matches[1], 10);
                            const errorMessage = matches[2];
                            let customError = {
                                status: statusCode,
                                message: errorMessage,
                            };
                            if (customError && customError.status && customError.status == 401) {
                                console.clear();
                                return this.handelTokenError(request, next);
                            }
                        }
                    }
                })
            );
        } else {
            return next.handle(request);
        }
    }

    checkUrl(request: HttpRequest<any>) {
        if(request.url.includes('/oauth/token')) {
            return false;
        }
        return true;
    }

    private handelTokenError(request: HttpRequest<any>, next: HttpHandler) {
        if(!localStorage.getItem('access_token') || !localStorage.getItem('refresh_token')){
            this.isRefreshing = false;
            this.userService.logout();
            window.location.href = "/home";
        }
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.userService.getNewOauthTokenDetails().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    // localStorage.setItem('access_token', token.access_token);
                    // localStorage.setItem('refresh_token', token.refresh_token);
                    this.userService.setTokenDetails(token);
                    this.refreshTokenSubject.next(token.access_token);
                    return next.handle(this.addTokenToRequest(request, token.access_token));
                }),
                catchError((err) => {
                    
                    this.isRefreshing = false;
                    this.userService.logout();
                    window.location.href = "/home";
                    return throwError(err);
                })
            );
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenToRequest(request, token)))
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, newToken: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${newToken}`,
            },
        });
    }
}
